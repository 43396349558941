import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue";
import _imports_0 from '@/assets/images/info.png';
const _hoisted_1 = {
  class: "recharge max-440"
};
const _hoisted_2 = {
  class: "top1"
};
const _hoisted_3 = {
  class: "mb-8"
};
const _hoisted_4 = {
  class: "mb-8"
};
const _hoisted_5 = {
  class: "cardName"
};
const _hoisted_6 = {
  class: "cardItem acea-row row-between-wrapper"
};
const _hoisted_7 = {
  class: "cardItem acea-row row-between-wrapper"
};
const _hoisted_8 = {
  key: 1,
  class: "acea-row row-center-wrapper",
  style: {
    "padding-top": "15px"
  }
};
const _hoisted_9 = {
  class: "mb-8"
};
const _hoisted_10 = {
  class: "AllInfo acea-row row-center-wrapper"
};
import NP from 'number-precision';
import { router } from '@/router';
import { computed, ref, getCurrentInstance } from 'vue';
import { getBalance, getCardData, getIdCard, withdraw } from '@/http';
import { showToast } from 'vant';
import { useUserStore } from "@/stores/user";
import { useI18n } from "vue-i18n";
export default {
  __name: 'withdrawal',
  emits: ['back'],
  setup(__props, {
    emit: __emit
  }) {
    var _walletBalance$value;
    const {
      t
    } = useI18n();
    const amount = ref('');
    const payPassword = ref('');
    const fee = computed(() => {
      if (withdrawalData.value.length && amount.value) {
        return NP.times(amount.value, withdrawalData.value[index.value].fee / 100);
      }
      return 0;
    });
    const emit = __emit;
    const emitBacks = () => {
      emit('back', 0);
    };
    const show = ref(false);
    let userBanner = ref(0);
    const address = ref('');
    const back = () => {
      router.go(-1);
    };
    const openUrls = () => {
      if (backCard.value !== 1) {
        router.push('/me?type=1');
      }
    };
    const cardAcount = computed(() => {
      const str = cardData.value.card;
      return str.substring(0, str.length - 4).padEnd(str.length, '*');
    });
    let cardData = ref({});
    const withdrawalData = ref([]);
    const index = ref(0);
    const userStore = useUserStore();
    const walletBalance = computed(() => userStore.walletBalance);
    const instance = getCurrentInstance();
    userBanner.value = instance.appContext.config.globalProperties.$public.keepFulls((_walletBalance$value = walletBalance.value) === null || _walletBalance$value === void 0 ? void 0 : _walletBalance$value.balance);
    userStore.getBalance();
    let backCard = ref(2);
    const _getIdCard = () => {
      getIdCard().then(res => {
        console.log(res);
        backCard.value = res.data;
        if (res.data === 1) {
          getCardData().then(res => {
            cardData.value = res.data;
          });
        }
      });
    };
    _getIdCard();
    const _withdraw = () => {
      if (backCard.value !== 1) {
        showToast(t('newCard.addCardInfo'));
        return false;
      }
      withdraw({
        amount: amount.value,
        pay_password: payPassword.value
      }).then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          router.back();
        }
      });
    };
    const formatter = val => {
      if (parseFloat(val) >= parseFloat(walletBalance.value.balance)) {
        return walletBalance.value.balance;
      } else {
        return val;
      }
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "back acea-row row-left",
        onClick: emitBacks
      }, [_createVNode(_component_van_icon, {
        name: "arrow-left",
        size: "20"
      }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('withdraw')), 1)]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_field, {
        disabled: "",
        border: false,
        modelValue: _unref(userBanner),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(userBanner) ? userBanner.value = $event : userBanner = $event),
        label: _ctx.$t('newCard.balance'),
        placeholder: _ctx.$t('please_enter_recharge_amount')
      }, {
        button: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("span", {
          style: {
            "color": "#FF3131"
          }
        }, "฿", -1)])),
        _: 1
      }, 8, ["modelValue", "label", "placeholder"])]), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('withdrawal_amount')), 1), _createVNode(_component_van_field, {
        formatter: formatter,
        border: false,
        placeholder: _ctx.$t('please_enter_withdrawal_amount'),
        class: "br-a__8 mb-18 vantInput",
        modelValue: amount.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => amount.value = $event),
        type: "number"
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('newCard.cardInfos')), 1), _createElementVNode("div", {
        class: _normalizeClass(["cardInfos", {
          'showCard': _unref(backCard) === 1
        }]),
        onClick: openUrls
      }, [_unref(backCard) === 1 ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createElementVNode("div", _hoisted_5, _toDisplayString(_unref(cardData).bank_name), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('newCard.number')), 1), _createElementVNode("span", null, _toDisplayString(cardAcount.value), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('newCard.name')), 1), _createElementVNode("span", null, _toDisplayString(_unref(cardData).name), 1)])], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_van_icon, {
        name: "plus",
        size: "30"
      })]))], 2), _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('transaction_password')), 1), _createVNode(_component_van_field, {
        border: false,
        type: "password",
        placeholder: _ctx.$t('please_enter_transaction_password'),
        class: "br-a__8 mb-18 vantInput",
        modelValue: payPassword.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => payPassword.value = $event)
      }, null, 8, ["placeholder", "modelValue"]), _createVNode(_component_van_button, {
        block: "",
        round: "",
        color: "#56F9AA",
        onClick: _withdraw
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('submit')), 1)]),
        _: 1
      })]), _createElementVNode("div", _hoisted_10, [_cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('newCard.customer_service')), 1)])], 64);
    };
  }
};